import React from 'react';
import './App.css';

function App() {
  const redirectToApp = () => {
    window.location.href = process.env.REACT_APP_APP_URL;
  };

  return (
    <div className="landing-page">
      <header className="header">
        <nav className="nav">
          <h1 className="logo">Zifit</h1>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <section className="hero">
          <h2>Welcome to Zifit</h2>
          <p>Your ultimate personal training platform. Join us and reach your fitness goals.</p>
          <button className="cta-button" onClick={redirectToApp}>Dashboard</button>
        </section>
      </header>
      <footer className="footer">
        <p>© 2024 Zifit. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
